import * as React from "react"
import { Link } from "gatsby"

// styles


// markup
const NotFoundPage = () => {
  return (
    <section className="p-4 m-4 justify-content-center align-items-center" >

<div className="row p-4">
   <h1 className=" col-12 text-center text-3xl font-bold">
        404
      </h1>

      <p className=" col-12 text-center text-xl">
        Page not found
      </p>

      <p className=" col-12 text-center text-xl">
        <Link to="/" className=" text-blue-500 hover:text-blue-700">
          Go back to the homepage
        </Link>
      </p>
</div>
     

    
    
    </section>
  )
}

export default NotFoundPage
